import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth-slice';
import appSlice from './app-slice';
// import coursesSlice from './courses-slice';
import uiSlice from './ui-slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    app: appSlice.reducer,
  },
});

export default store;
