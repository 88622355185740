import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
  data: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState: defaultState,
  reducers: {
    setField(state, actions) {
      state.data = actions.payload;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice;
