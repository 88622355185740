export const resources = [
  'courses',
  'materials',
  'quizzes',
  'users',
  'emails',
  'plans',
  'postscripts',
];

export const actions = ['new', 'edit', 'show'];

export const fields = {
  materials: [
    '_id',
    'name',
    'publishedBy',
    'course',
    'season',
    'year',
    'lastModified',
    'createdAt',
    'downloads',
    'fileType',
    'quiz',
    'fileName',
    'fullFileName',
    'fileSize',
  ],
  courses: [
    '_id',
    'name',
    'major',
    'category',
    'lastModified',
    'createdAt',
    'keywords',
  ],
  quizzes: [
    '_id',
    'name',
    'duration',
    'course',
    'publishedBy',
    'lastModified',
    'quizSlug',
    'createdAt',
  ],
  emails: ['_id', 'englishName', 'arabicName', 'email', 'department'],
  plans: [
    '_id',
    'englishName',
    'arabicName',
    'major',
    'year',
    'image',
    'fullFileName',
  ],
  users: [
    '_id',
    'firstName',
    'lastName',
    'active',
    'role',
    'lastModified',
    'createdAt',
  ],
  postscripts: ['_id', 'name', 'lastModified', 'createdAt'],
};

export const timeMapper = (uploadedDate: Date) => {
  const uploadDateObj: any = new Date(uploadedDate);
  const DateNowObj: any = new Date();
  const milliseconds = Math.abs(DateNowObj - uploadDateObj);
  const minutes = milliseconds / 6e4;
  const hours = milliseconds / 36e5;
  if (hours > 24) {
    return (
      uploadDateObj.getFullYear() +
      '/' +
      (uploadDateObj.getMonth() + 1) +
      '/' +
      uploadDateObj.getDate()
    );
  } else if (hours >= 1) {
    return `${Math.floor(hours)} hour${Math.floor(hours) > 1 ? 's' : ''} ago`;
  } else if (minutes >= 1) {
    return `${Math.floor(minutes)} minute${
      Math.floor(minutes) > 1 ? 's' : ''
    } ago`;
  } else {
    return 'before few seconds';
  }
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const fileSizeFormatter = (bytes: number, si = true, dp = 1) => {
  if (!bytes) return '0.00';
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const borderRadius = '0.8rem';
