import { colors, breakpoints } from 'lib/themes/styles';
import { createTheme, Theme, responsiveFontSizes } from '@mui/material';

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.green,
    },
    error: {
      main: colors.red,
    },
    text: {
      primary: '#000000',
      secondary: '#00000099',
      // black: '#000000',
    },
  },
  typography: {},
  breakpoints: { values: { ...breakpoints } },
});

export default responsiveFontSizes(theme);
