import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import rtlPlugin from 'stylis-plugin-rtl';
// import {
//   StyleSheetManager,
//   ThemeProvider as StyledThemeProvider,
// } from 'styled-components';

import { Provider } from 'react-redux';
import Root from 'pages/Root';
import store from 'store/index';
import defaultTheme from 'lib/themes/defaultTheme';
import useHttp from 'hooks/use-http';
import { visitorsCount } from 'api/api';

// const primaryTheme = createTheme(defaultTheme);
// const flippedPrimaryTheme = createTheme(flippedDefaultTheme);
// const { direction } = useSelector((state) => state.ui);

// console.log(direction);
// const theme = () => {
//   if (direction === 'rtl') return flippedPrimaryTheme;
//   else return primaryTheme;
// };

// useEffect(() => {
//   console.log(document.body.dir);
//   document.body.dir = direction || 'ltr';
// }, [direction]);

const App = () => {
  const { sendRequest } = useHttp(visitorsCount, true);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Root />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
