import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
  snackBar: {
    open: false,
    message: '',
  },
  modal: {
    open: false,
    message: '',
    data: {},
  },
  openViewer: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: defaultState,
  reducers: {
    openSnackBar(state, actions) {
      const newSnackBar = { open: true, message: actions.payload };
      state.snackBar = newSnackBar;
    },
    closeSnackBar(state) {
      const newSnackBar = { open: false, message: '' };
      state.snackBar = newSnackBar;
    },
    openModal(state, actions) {
      const newModal = {
        open: true,
        message: actions.payload.message,
        data: actions.payload.data,
      };

      state.modal = newModal;
    },
    closeModal(state) {
      const newModal = { open: false, message: '' };
      state.modal = newModal;
    },
    openViewerHandler(state) {
      state.openViewer = true;
    },
    closeViewerHandler(state) {
      state.openViewer = false;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
