import { Colors } from './core';

export const colors: Colors = {
  grey: '#505050',
  lightGrey: '#e2e1e1',
  blue: '#2fbad9',
  green: '#5BC040',
  red: '#F24F50',
  yellow: '#F2BC1B',
  lightRed: '#fcd8d9',
  fWhiteColor: '#fdfdfd',
};

export const hoverColors = {
  blue: '#2b97af',
  red: '#bf3e41',
  lightGrey: '#cccccc',
};

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const borderRadius = '0.8rem';
