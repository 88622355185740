import axios from 'axios';
import Cookies from 'js-cookie';

import { fields } from 'assets/static/constant';
const url = process.env.REACT_APP_API_URL + '/api/v1';

export const login = async (data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${url}/users/signin`,
      data: {
        email: data.email,
        password: data.password,
      },
    });

    return await response.data;
  } catch (error) {
    let message = '';
    if (error.response) {
      message = error.response.data.message;
    } else {
      message = error;
    }
    throw new Error(message || 'couldn\'t login please try again later');
  }
};

export const fetchResource = async (resource) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      url: `${url}/${resource}`,
    });

    return response.data.data.map((obj) => {
      return { id: obj._id, name: obj.name, script: obj.script };
    });
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const getAll = async ({ resource, active }) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      url: `${url}/${resource}${active ? '/recycle-bin' : ''}`,
    });
    if (fields[resource]) {
      const filiteredFields = fields[resource]
        ? fields[resource].filter((item) => item !== 'fileName')
        : [];
      if (filiteredFields.length === 0) return response.data.data;
      return response.data.data.map((row) => {
        const obj = {};
        filiteredFields.forEach((field) => (obj[field] = row[field]));

        return obj;
      });
    }

    return response.data.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'Something went wrong!'
    );
  }
};

export const createNewResource = async (data) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const headers = {
      authorization: `Bearer ${jwt}`,
    };
    if (data.resource === 'materials') {
      headers['Content-Type'] = 'multipart/form-data';
    }

    const response = await axios({
      method: 'POST',
      url: `${url}/${data.resource}${data.query ? '?' + data.query : ''}`,
      headers,
      data: data.data,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Something went wrong!');
  }
};

export const getOne = async (data) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const headers = {
      authorization: `Bearer ${jwt}`,
    };
    const response = await axios({
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      url: `${url}/${data.resource}/${data.id}`,
      headers,
    });
    return response.data.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const deleteOne = async (data) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'DELETE',
      url: `${url}/${data.resource}/${data.id}${data.query ? '?' + data.query : ''
        }`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const updateOne = async (data) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'PATCH',
      url: `${url}/${data.resource}/${data.id}${data.query ? '?' + data.query : ''
        }`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      data: data.data,
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const disableOne = async ({ resource, id }) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'DELETE',
      url: `${url}/${resource}/disable/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const activateOne = async ({ resource, id }) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'POST',
      url: `${url}/${resource}/activate/${id}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const updateUserPassword = async (id, data) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'PATCH',
      url: `${url}/users/${id}/change-password`,
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
}

export const getAllCourses = async () => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'GET',
      url: `${url}/courses`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const getCourse = async (slug) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const response = await axios({
      method: 'GET',
      url: `${url}/courses/slug?slug=${slug}`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return response.data.data[0];
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const visitorsCount = async () => {
  try {
    await axios({
      method: 'POST',
      url: `${url}/metrics/visitors`,
    });
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const dashboardMetrics = async (year) => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const headers = {
      authorization: `Bearer ${jwt}`,
    };
    const response = await axios({
      method: 'GET',
      headers,
      url: `${url}/metrics/dashboard${year ? `?year=${year}` : ''}`,
    });
    return response.data.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const resetMetrics = async () => {
  try {
    const jwt = await JSON.parse(Cookies.get('auth')).token;
    const headers = {
      authorization: `Bearer ${jwt}`,
    };
    await axios({
      method: 'delete',
      headers,
      url: `${url}/metrics`,
    });
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

export const requestFile = async (restOfTheURL) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_DOMAIN_URL}/downloads/${restOfTheURL}`,
      responseType: 'blob',
    });

    return URL.createObjectURL(res.data);
  } catch (error) {
    throw new Error(
      error.response.data.message || 'something went wrong!'
    );
  }
};

