import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

const NotFound = React.lazy(() => import('./NotFound'));
const Login = React.lazy(() => import('./login/Login'));
const Admin = React.lazy(() => import('./admin/Admin'));

function Root() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/' element={<Navigate to='/admin/dashboard' />} />
        <Route path='/login' element={<Login />} />
        <Route path='/admin/*' element={<Admin />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default Root;
