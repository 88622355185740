import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  token: '',
  role: '',
  isLoggedIn: false,
  expiresIn: null,
  generalUserData: {},
};

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedData = Cookies.get('auth');
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    const remainingTime = calculateRemainingTime(parsedData.expiresIn);
    if (remainingTime <= 3600) {
      Cookies.remove('auth');
      return initialState;
    }
    return parsedData;
  }
  return initialState;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: retrieveStoredToken(),
  reducers: {
    logout(state) {
      state.isLoggedIn = false;
      state.expiresIn = null;
      state.token = '';
      state.role = '';
    },
    login(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.expiresIn = action.payload.expiresIn;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.generalUserData = action.payload.generalUserData;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
